<template>
	<v-card flat v-if="initialized">
		<v-card-title> Kredi Bilgileri </v-card-title>
		<v-card-text>
			<v-row dense>
				<v-col cols="12" md="4">
					<v-row>
						<v-col cols="12" md="12" class="d-flex align-center">
							<v-checkbox
								class="pt-0 mt-0"
								v-model="cmsCredit.isCalculateCredit"
								label="Kredi Hesaplama Aktif"
								hide-details="auto"
							></v-checkbox>
						</v-col>
						<v-col cols="12" md="12" class="d-flex align-center">
							<v-checkbox
								class="pt-0 mt-0"
								v-model="cmsCredit.isCreditByInsurancePrice"
								label="Kasko Fiyatına Göre Kredi Hesapla"
								hide-details="auto"
							></v-checkbox>
						</v-col>
						<v-col cols="12" md="12">
							<v-text-field
								label="Kredi Oranı"
								outlined
								hide-details="auto"
								flat
								dense
								color="teal"
								v-model="cmsCredit.creditRate"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="12">
							<v-text-field
								label="KKDF"
								outlined
								hide-details="auto"
								flat
								dense
								color="teal"
								v-model="cmsCredit.creditKkdf"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="12">
							<v-text-field
								label="BSMV"
								outlined
								hide-details="auto"
								flat
								dense
								color="teal"
								v-model="cmsCredit.creditBsmv"
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<div class="mt-10">
				<v-card-title class="pa-0">
					Kredi Oranları
					<v-spacer></v-spacer>
					<v-btn depressed class="text-capitalize" @click="add">
						<v-icon left x-small v-text="'fas fa-plus'"></v-icon>
						yeni
					</v-btn>
				</v-card-title>
				<v-card-text class="pa-0">
					<v-list dense>
						<v-list-item dense class="px-0 v-i" v-if="cmsCredit.creditSetting.length === 0">
							<v-list-item-content class="justify-center text-center">
								Henüz bir kredin oranı eklenmemiş.
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							dense
							class="px-0"
							v-for="(cr, index) in $v.cmsCredit.creditSetting.$each.$iter"
							:key="`credit-${index}`"
						>
							<v-list-item-content class="d-flex flex-row">
								<v-row>
									<v-col cols="12" md="3">
										<v-text-field-money
											v-model="cr.minPrice.$model"
											v-bind:label="'Min Araç Tutarı'"
											v-bind:properties="{
												inputmode: 'numeric',
												outlined: true,
												dense: true,
												flat: true,
												errorMessages: minPriceErrors(index),
												hideDetails: 'auto',
											}"
											v-bind:options="{
												locale: 'tr-TR',
												precision: 0,
											}"
										/>
									</v-col>
									<v-col cols="12" md="3">
										<v-text-field-money
											v-model="cr.maxPrice.$model"
											v-bind:label="'Max Araç Tutarı'"
											v-bind:properties="{
												inputmode: 'numeric',
												outlined: true,
												dense: true,
												flat: true,
												errorMessages: maxPriceErrors(index),
												hideDetails: 'auto',
											}"
											v-bind:options="{
												locale: 'tr-TR',
												precision: 0,
											}"
										/>
									</v-col>
									<v-col cols="12" md="3">
										<v-text-field
											inputmode="numeric"
											label="Max Vade"
											outlined
											hide-details="auto"
											flat
											dense
											color="teal"
											v-model="cr.maxInstallment.$model"
											:error-messages="maxInstallmentErrors(index)"
										></v-text-field>
									</v-col>
									<v-col cols="12" md="3">
										<v-text-field
											inputmode="numeric"
											label="Max Kredi Oranı"
											outlined
											hide-details="auto"
											flat
											dense
											color="teal"
											v-model="cr.maxCreditRate.$model"
											:error-messages="maxCreditRateErrors(index)"
										></v-text-field>
									</v-col>
								</v-row>
							</v-list-item-content>
							<v-list-item-action>
								<v-btn outlined color="red darken-4" depressed class="text-capitalize" @click="remove(index)">
									<v-icon left x-small v-text="'fas fa-trash'"></v-icon>
									sil
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card-text>
			</div>

			<div class="d-flex justify-start py-5">
				<v-btn
					:loading="savePreloader"
					color="teal"
					class="text-capitalize"
					:dark="!$v.cmsCredit.$invalid"
					@click="save"
					:disabled="$v.cmsCredit.$invalid"
				>
					Değişiklikleri Kaydet
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { FETCH_SITE_SETTINGS, UPDATE_CREDIT_SETTING } from '@/store/modules/company.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import creditInformationValidationMixin from '@/mixins/validations/backoffice/creditInformationValidationMixin';

export default {
	name: 'Credit',
	mixins: [validationMixin, creditInformationValidationMixin],
	data() {
		return {
			savePreloader: false,
			cmsCredit: {
				isCreditByInsurancePrice: false,
				creditRate: null,
				isCalculateCredit: false,
				creditSetting: [],
				creditKkdf: null,
				creditBsmv: null,
			},
		};
	},

	computed: {
		...mapGetters(['settings']),
		initialized() {
			return this.cmsCredit !== null;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings();
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			updateCreditSetting: UPDATE_CREDIT_SETTING,
		}),
		add() {
			this.cmsCredit.creditSetting.push({
				minPrice: null,
				maxPrice: null,
				maxInstallment: null,
				maxCreditRate: null,
			});
		},

		remove(index) {
			this.cmsCredit.creditSetting.splice(index, 1);
		},
		save() {
			this.savePreloader = true;
			this.updateCreditSetting(this.cmsCredit)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı!',
						text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
					});
				})
				.finally(() => {
					this.savePreloader = false;
				});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.cmsCredit = {
					isCreditByInsurancePrice: company.cmpIsCreditByInsurancePrice,
					creditRate: company.cmpCreditRate,
					isCalculateCredit: company.cmpIsCalculateCredit,
					creditSetting: company.creditSetting.map((x) => ({
						minPrice: x.ccsMinPrice,
						maxPrice: x.ccsMaxPrice,
						maxInstallment: x.ccsMaxInstallment,
						maxCreditRate: x.ccsMaxCreditRate,
					})),
					creditKkdf: company.cmpCreditKkdf,
					creditBsmv: company.cmpCreditBsmv,
				};
			},
			deep: true,
		},
	},
};
</script>

<style></style>
