/* eslint-disable no-unused-expressions */
import { required } from 'vuelidate/lib/validators';

const creditInformationValidationMixin = {
	validations: {
		cmsCredit: {
			creditSetting: {
				$each: {
					minPrice: {
						required,
					},
					maxPrice: {
						required,
					},
					maxInstallment: {
						required,
					},
					maxCreditRate: {
						required,
					},
				},
			},
		},
	},
	methods: {
		// validation methods for this page
		minPriceErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsCredit.creditSetting.$each[index].minPrice;
			if (!$dirty) return errors;
			!required && errors.push('mimimum tutar giriniz.');
			return errors;
		},
		maxPriceErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsCredit.creditSetting.$each[index].maxPrice;
			if (!$dirty) return errors;
			!required && errors.push('maksimum tutar giriniz.');
			return errors;
		},
		maxInstallmentErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsCredit.creditSetting.$each[index].maxInstallment;
			if (!$dirty) return errors;
			!required && errors.push('maksimum taksit sayısı giriniz.');
			return errors;
		},
		maxCreditRateErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsCredit.creditSetting.$each[index].maxCreditRate;
			if (!$dirty) return errors;
			!required && errors.push('maksimum kredi oranını giriniz.');
			return errors;
		},
	},
};

export default creditInformationValidationMixin;
